import React, { useState, useRef, useCallback } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import EndCallIcon from "../../../icons/EndCallIcon";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useAppState } from "../../../state";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      // background: theme.brand,
      color: "white",
      "&:hover": {
        // background: '#600101',
      },
    },
  })
);

// const IconContainer = styled('div')({
//   display: 'flex',
//   justifyContent: 'center',
//   width: '1.5em',
//   marginRight: '0.3em',
// });

export function endMeeting(roomId: string, participantSid: string) {
  const api_key = window.sessionStorage.getItem("m_key");
  const m_key = api_key ? api_key : "";

  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/internal/room/${roomId}/status`,
    {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "X-API-KEY": m_key,
      },
      body: JSON.stringify({
        participantSid,
      }),
    }
  );
}

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { meeting } = useAppState();

  const stopMeeting = useCallback(() => {
    return endMeeting(room!.sid, localParticipant!.sid).then(async (res) => {
      // console.log("endMeeting--res", res);
      const json = await res.json();
      console.log("endMeeting--json", json);
    });
  }, [room]);

  return (
    <>
      <Button
        onClick={() => setMenuOpen((isOpen) => !isOpen)}
        ref={anchorRef}
        className={clsx(classes.button, props.className)}
        startIcon={<EndCallIcon />}
        data-cy-disconnect
      >
        {/* Disconnect */}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : "bottom",
          horizontal: "center",
        }}
      >
        {meeting && Boolean(meeting.isChaperoning) === true && (
          <MenuItem onClick={() => stopMeeting()}>
            {/* <IconContainer>
            <SettingsIcon />
          </IconContainer> */}
            <Typography variant="body1">End Meeting For All</Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            room!.disconnect();
          }}
        >
          {/* <IconContainer>
            <BackgroundIcon />
          </IconContainer> */}
          <Typography variant="body1">Leave Meeting</Typography>
        </MenuItem>
      </MenuContainer>
    </>

    // <Tooltip
    //   title="Phone Off"
    //   placement="top"
    //   PopperProps={{ disablePortal: true }}
    //   style={{ cursor: 'pointer' }}
    // >
    //   <span>
    //     <Button
    //       onClick={() => room!.disconnect()}
    //       className={clsx(classes.button, props.className)}
    //       startIcon={<EndCallIcon />}
    //       data-cy-disconnect
    //     >
    //       {/* Disconnect */}
    //     </Button>

    //   </span>
    // </Tooltip>
  );
}
