import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import { isMobile } from "../../utils";
import Menu from "./Menu/Menu";
// import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { Typography, Grid, Hidden } from "@material-ui/core";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleParticipantButton from "../Buttons/ToggleParticipantButton/ToggleParticipantButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import { useAppState } from "../../state";
import InviteButton from "../CallInvite/InviteButton";
import TroubleshootButton from "../Buttons/TroubleshootButton";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // backgroundColor: theme.palette.background.default,
      gridArea: "1 / 1 / 2 / 3",
      backgroundColor: "#191b1d",
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: "fixed",
      // position: 'absolute',
      display: "flex",
      padding: "0 1.43em",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: "fixed",
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: "104px",
      background: "rgba(0, 0, 0, 0.5)",
      "& h6": {
        color: "white",
      },
      "& button": {
        background: "white",
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: "0 2em",
        "&:hover": {
          color: "#600101",
          border: `2px solid #600101`,
          background: "#FFE9E7",
        },
      },
    },
    hideMobile: {
      display: "initial",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    buttonContainer: {
      flexWrap: "nowrap",
    },
    inviteLinkContainer: {
      "& a": {
        color: "white",
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare, room } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const { meeting, user, getToken } = useAppState();
  const params: any = useParams();
  const api_key = window.sessionStorage.getItem("m_key");
  const m_key = api_key ? api_key : "";
  // const { room } = useVideoContext();
  // const participants = useParticipants();

  /*  const meeting_id = params?.meetingId;
  const participantName = JSON.stringify(localStorage.getItem("userIdentity")); */

  return (
    <>
      {isSharingScreen && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.screenShareBanner}
        >
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid
                container
                justifyContent="flex-start"
                className={classes.inviteLinkContainer}
              >
                <TroubleshootButton />
              </Grid>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid
              container
              justifyContent="center"
              className={classes.buttonContainer}
            >
              <ToggleAudioButton disabled={isReconnecting} />
              {/* <ToggleVideoButton disabled={isReconnecting} /> */}
              {meeting &&
                !meeting.disabled_screen_share &&
                Boolean(meeting.isChaperoning) !== true &&
                !isSharingScreen &&
                !isMobile && (
                  <ToggleScreenShareButton disabled={isReconnecting} />
                )}
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !==
                "true" && <ToggleChatButton />}
              <ToggleParticipantButton />
              <EndCallButton />
              <Menu />
              {/* <Hidden smDown>
                <Menu />
              </Hidden> */}
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid
                container
                justifyContent="flex-end"
                className={classes.inviteLinkContainer}
              >
                <InviteButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
